import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "../Pages/Home";
import PageNotFound from "../Pages/PageNotFound";
import SeeAll from "../Pages/SeeAll";
import ErrorPage from "../Pages/Error";
import PacmanLoader from "react-spinners/PacmanLoader"
import { styled } from "styled-components";
import Form from "../Pages/Form";
import Tnc from "../Pages/Tnc";
import { getRequest } from "../lib";
import Cookies from "js-cookies";

const Routing = () => {
  // const q = new URLSearchParams(window.location.search);
  // const nav = useNavigate();
  // useEffect(() => {
  //   if (window.location.pathname == "/games") {
  //     checkSubApi()
  //   }
  // })
  // const checkSubApi = async () => {
  //   const [e0, data] = await getRequest(
  //     `/subscribe-user/check-sub?msisdn=${q.get("msisdn")
  //     }`
  //   );
  //   // setLoading(false);
  //   if (e0) {
  //     return [e0];
  //   }
  //   if (data.result == 1) {
  //     // toast.info(data.msg);
  //     Cookies.getItem("h2n-kenya-msisdn", q.get("msisdn"), { expires: 1 });
  //     localStorage.getItem("msisdn", q.get("msisdn"));
  //     // return nav("/games");
  //   } else {
  //     // return nav("/");
  //   }
  // };
  return (<>
    <React.Suspense fallback={
      <SuspenseSpinner>
        <PacmanLoader
          size={65}
          color="yellow" />

      </SuspenseSpinner>
    }>
      <Routes>
        <Route path="/" exact={true} element={<Form />} />
        <Route path="/tnc" exact={true} element={<Tnc />} />
        <Route path="/games" exact={true} element={<Home />}></Route>
        <Route path="/seeAll" exact={true} element={<SeeAll />}></Route>
        <Route path="*" exact={true} element={<PageNotFound />}></Route>
        <Route path="/error" exact={true} element={<ErrorPage />}></Route>
      </Routes>
    </React.Suspense>
  </>)
}
export default Routing;

const SuspenseSpinner = styled.div`
 background-color: #23242a;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;